import { Box, Container, Flex, Link, Text } from "@chakra-ui/layout"
import { Button } from "@chakra-ui/react"
import React from "react"
import { Trans } from "gatsby-plugin-react-i18next"

const HomeHeader = () => {
  return (
    <Container
      maxW={["95%", "95%", "75%", "75%"]}
      mt={[0, 0, "lg", "lg"]}
      color="whiteAlpha.800"
      fontFamily="BookTitle"
      px={[0, 0, "sm", "sm"]}
    >
      <Flex
        align="center"
        justify="center"
        flexDirection={["column", "row", "row", "row"]}
      >
        <Box w="100%">
          <Box m="0 auto" w="100%" position="relative" right={[1, 1, 0, 0]}>
            <div
              dangerouslySetInnerHTML={{
                __html: `
                  <video preload="auto" autoPlay playsinline loop muted>
                    <source src="/book-video3.mp4" type="video/mp4" />
                  </video>
                `,
              }}
            />
          </Box>
        </Box>
        <Box w="100%" px={[0, 0, "xs", "xs"]}>
          <Flex my={[0, 0, "xs", "xs"]} align="center" justify="center">
            <Text
              fontSize={[20, 20, 35, 35]}
              fontWeight={800}
              fontFamily="BookTitle"
            >
              NUMERO
            </Text>
            <Text
              fontSize={[25, 25, 40, 40]}
              fontWeight={800}
              fontFamily="NumberTitle"
              ml={2}
              mb={1}
            >
              10
            </Text>
          </Flex>

          <Text
            fontSize={[12, 12, 15, 15]}
            fontWeight={400}
            fontFamily="Poppins"
          >
            <Trans>
              Le premier livre d'Elisa Parron retraçant ses 8 premières années
              entre le rap français et le Paris Saint-Germain.
            </Trans>
          </Text>

          <Text
            fontSize={[12, 12, 15, 15]}
            fontWeight={400}
            fontFamily="Poppins"
            textTransform="uppercase"
            mt="sm"
          >
            <Trans>Disponible le 15 Décembre</Trans>
          </Text>

          <Button
            borderRadius={5}
            fontSize={[12, 12, 15, 15]}
            size="md"
            fontFamily="Poppins"
            color="whiteAlpha.800"
            bg="transparent"
            border="1px solid white"
            fontWeight={800}
            m="0 auto"
            _focus={{
              boxShadow: "none",
            }}
            mt={["xs", "sm", "md", "md"]}
            _hover={{
              bgColor: "#333333",
              color: "white",
              textDecor: "none",
            }}
          >
            <Link href="https://elisaparron.shop">
              <Trans>BUY NOW</Trans>
            </Link>
          </Button>
        </Box>
      </Flex>
    </Container>
  )
}

export default HomeHeader
