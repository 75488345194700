import { graphql } from "gatsby"
import React from "react"
import HomeHeader from "../components/HomeHeader/HomeHeader"
import Layout from "../components/Layout/Layout"

const IndexPage = () => {
  return (
    <Layout navbarBottom>
      <HomeHeader />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
